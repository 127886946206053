var mapboxAccessToken = 'pk.eyJ1IjoiZ2VyZ28iLCJhIjoiY2p2bGU0NnFjMHljOTN6bWcxb2hxM2FoYSJ9.ANF8oKFwcbyc0r33XGoh1w';
var map = L.map('map').setView([51.493, -0.027], 2);

map.attributionControl.addAttribution('Map data © <a href="https://www.openstreetmap.org" title="OpenStreetMap">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/3.0/" title="CC-BY-SA">CC-BY-SA</a>');
map.attributionControl.addAttribution('Imagery © <a href="https://www.mapbox.com/" title="Mapbox">Mapbox</a>');
map.attributionControl.addAttribution('Player data © <a href="http://results.ittf.bornan.net/ittf-web-results/html/TTE5000/results.html#/results" title="ITTF Bornan">ITTF Bornan</a>');

L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + mapboxAccessToken, {
    id: 'mapbox.light',
    attribution: ""
}).addTo(map);

function loadJSON(callback) {
    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', 'countries_players_rounds.geojson', true);
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);
}

var countriesJSON;
var geojson;

var gender = 'w'
var KOround = '128'

var roundName = ""
switch(KOround) {
    case "128":
        roundName = 'Round of ' + KOround;
        break;
    case "8":
        roundName = 'Quarterfinals';
        break;
    case "4":
        roundName = 'Semifinals';
        break;
    case "2":
        roundName = 'Final';
        break;
    case "1":
        roundName = 'Winner';
        break;
    default:
        roundName = 'Last ' + KOround;
        break;

}

function init() {
    loadJSON(function(response) {
    countriesJSON = JSON.parse(response);
    
    geojson = L.geoJson(countriesJSON, {
        style: style,
        onEachFeature: onEachFeature
        }).addTo(map);
    });
}

init();

// colouring as per number of players
function getColor(d) {
    return d == 6 ? '#005824' :
           d == 5 ? '#238b45' :
           d == 4 ? '#41ae76' :
           d == 3 ? '#66c2a4' :
           d == 2 ? '#99d8c9' :
           d == 1 ? '#ccece6' :
                    '#edf8fb' ;
}

// default styling
function style(feature) {
    return {
        fillColor: getColor(feature.properties.tt && feature.properties.tt[gender] && feature.properties.tt[gender][KOround] ? feature.properties.tt[gender][KOround] : 0 ),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
    };
}

// we are setting this for mouseOver
function highlightFeature(e) {
    var layer = e.target;

    layer.setStyle({
        weight: 4,
        color: '#aaa',
        dashArray: '',
        fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
    }
    info.update(layer.feature.properties);
}

// style for mouseOut
function resetHighlight(e) {
    geojson.resetStyle(e.target);
    info.update();
}

// style when clicked
function zoomToFeature(e) {
    map.fitBounds(e.target.getBounds());
}

function onEachFeature(feature, layer) {
    layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature
    });
}

// info panels
var info = L.control();

info.onAdd = function (map) {
    this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
    this.update();
    return this._div;
};

// method that we will use to update the control based on feature properties passed
info.update = function (props) {
    this._div.innerHTML = '<h4>2019 ITTF WCh ' + (gender == 'm' ? 'Men' : 'Women' ) + ' - ' + roundName + '</h4>' +  (props ?
        '<b>' + props.ADMIN + '</b><br />' + (props.tt && props.tt[gender] && props.tt[gender][KOround] ? props.tt[gender][KOround]: 'No') + ' player' + (props.tt && props.tt[gender] && props.tt[gender][KOround] == 1 ? '' : 's') + ' in round'
        : 'Hover over a country');
};

info.addTo(map);

// custom legend
var legend = L.control({position: 'bottomright'});

legend.onAdd = function (map) {

    var div = L.DomUtil.create('div', 'info legend'),
        grades = [0, 1, 2, 3, 4, 5, 6],
        labels = [];

    // loop through our density intervals and generate a label with a colored square for each interval
    for (var i = 0; i < grades.length; i++) {
        div.innerHTML +=
            '<i style="background:' + getColor(grades[i]) + '"></i> ' +
            grades[i] + '<br/>';
    }

    return div;
};

legend.addTo(map);
